import api from "@/api";
import Vue from "vue"

export default {
  namespaced: true,
  state: {
    loginInitialized: false,
    loggedIn: false,
    token: null,
    groups: [],
    authData: {},
    email: "",
  },
  getters: {
    getLoggedIn(state) {
      return state.loggedIn;
    },
  },
  mutations: {
    setLoggedIn(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    setAuthData(state, val) {
      state.authData = val;
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("tiziAuthToken", token);
    },
    SET_GROUPS(state, value) {
      state.groups = value;
    },
    setLoginInitialized(state, val) {
      state.loginInitialized = val;
    },
    setEmptyAuthDataFields(state, data){
      for (const key of Object.keys(data)){
        if (!state.authData[key]){
          const value = data[key]
          if (value) {
            Vue.set(state.authData, key, value)
          }
        }
      }
    }
  },
  actions: {
    updateLoginInitialized({ commit }, val) {
      commit("setLoginInitialized", val);
    },
    updateLoggedIn({ commit }, loggedIn) {
      commit("setLoggedIn", loggedIn);
    },
    updateToken({ commit }, token) {
      commit("setToken", token);
    },
    updateEmptyAuthDataFields({ commit }, data) {
      // takes keys from data that are empty in state.authData and sets them
      commit("setEmptyAuthDataFields", data);
    },
    getGroups(context) {
      api.Util.axios.get("/groups/").then((response) => {
        context.commit("SET_GROUPS", response.data.results);
      });
    },
    socialLogin(context, data) {
      return new Promise((resolve, reject) => {
        api
          .socialLogin(data)
          .then((response) => {
            const token = response.data?.token;
            if (!token) reject("No token given back.");
            localStorage.setItem("tiziAuthToken", token);
            context.dispatch("tryLogin").then(() => {
              resolve();
            });
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    passwordLogin(context, data) {
      return new Promise((resolve, reject) => {
        api
          .login(data)
          .then((response) => {
            const token = response.data?.access;
            const refreshToken = response.data?.refresh;
            if (!token) reject("No token given back.");
            localStorage.setItem("tiziAuthToken", token);
            localStorage.setItem("tiziAuthRefreshToken", refreshToken);
            context.dispatch("tryLogin").then(() => {
              resolve();
            });
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    tryLogin(context) {
      return new Promise((resolve, reject) => {
        api.Util.axios
          .get("/users/me/")
          .then((response) => {
            const data = response.data;
            context.commit("setLoggedIn", true);
            context.commit("setAuthData", data);
            context
              .dispatch("getGroups")
              .then(() => {
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    logOut(context) {
      return new Promise((resolve) => {
        context.commit("setLoggedIn", false);
        context.commit("setIsManager", false);
        context.commit("setAuthData", "");
        resolve();
      });
    },
  },
};
